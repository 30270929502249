import Axios from 'axios'
import { GetUserResponse } from '../interfaces/api'
import { refreshInterceptor, setNewAccessToken } from './apis'
import { API_WIDGET_RESPONSE, GetCourseResponse, PostCodeToEmail } from '../interfaces/api-widget'
const { REACT_APP_WIDGET_API } = process.env

if (!REACT_APP_WIDGET_API) console.error('REACT_APP_WIDGET_API to be declared on your system environments variables')

const baseURL = `${REACT_APP_WIDGET_API}`

export const ApiWidget = Axios.create({ baseURL })

ApiWidget.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiWidget)
)

export const getActiveContent = (courseId: string) =>
    ApiWidget.get<GetCourseResponse>(`/v1/lxp/active-course/${courseId}`).then(({ data }) => data.data)

export const sendCodeToEmail = (email: string, captcha_token: string) =>
    ApiWidget.post<PostCodeToEmail>(`/v1/auth/passwordless-validation`, {
        email,
        captcha_token,
    }).then(({ data }) => data.data)

export const loginFromAdmin = (code: string) =>
    ApiWidget.get<API_WIDGET_RESPONSE<GetUserResponse>>(`${process.env.REACT_APP_WIDGET_API}/v1/lxp/get-user/${code}`).then(({ data }) => {
        setNewAccessToken(data.data.token)
        return data
    })

export const givePulse = async (params: GivePulseParams) =>
    ApiWidget.post<API_WIDGET_RESPONSE<{ voteData: GivePulseDTO }>>(`/v1/vote`, params).then(({ data }) => data.data.voteData)

export const getMyTeamData = async () => ApiWidget.get<API_WIDGET_RESPONSE<MyTeamDataDTO>>(`/v1/user/info`).then(({ data }) => data.data)

export const teamCallToCoach = async ({ tableNumber }: { tableNumber: number }) =>
    ApiWidget.post<API_WIDGET_RESPONSE<{ message: string }>>(`/v1/help-request/activationTeam`, {
        tableNumber,
    }).then(({ data }) => data.data)

// Interfaces
interface GivePulseParams {
    _algorithmId?: string
    _profileToId: string
}

interface GivePulseDTO {
    _profileFromId: string
    _profileToId: string
    _algorithmId: string
    type?: any
    sqsCreatedAt: number
}
export type MyTeamDataDTO = {
    me: {
        voteData: {
            _profileFromId: string
            _profileToId: string // este
            createdAt: string
        }
    }
    table: {
        tableNumber: number
        voteData: {
            hasVoted: boolean
        }
        isMentorTeam: boolean
        isExpertMentorTeam: boolean
        tableNumberToVote: number
        facilitator: string
        positions: {
            isMentor: boolean
            isExpertMentor: boolean
            isExpertMentorTeam: boolean
            available: boolean
            number: number
            _profileId: string
            _userId: {
                _id: string
                lastname: string
                name: string
                urlImage?: string
            }
            content: number
        }[]
        helpRequest?: any
    } | null
    team: {
        settings: {
            enabledActivationTeam: boolean
        }
    }
}
