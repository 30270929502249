import { useEffect, useState } from 'react'
import styles from './SessionProgressTracker.module.css'
import { PlanSessionDTO } from '../../infrastructure/api-content'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DayJS from '../../helpers/DayJS'
import { useUserCourse } from '../../hooks/queries/useUserCourses'
import styled from 'styled-components'

export const SessionProgressTracker = ({ session, stepId }: { session: PlanSessionDTO; stepId?: string }) => {
    const { t } = useTranslation('course')
    const navigate = useNavigate()
    const { slugOrId } = useParams()
    const { course } = useUserCourse(slugOrId)

    const [state, setState] = useState({
        isInRange: false,
        currentStep: 1,
        totalSteps: session.steps.length,
        leftTime: '',
        sessionTime: '',
        leftPercentage: '',
    })

    const { isInRange, currentStep, totalSteps, sessionTime } = state

    // Calculate current step index
    const stepIndex = session.steps.findIndex(({ id }) => id === stepId)
    useEffect(() => {
        const currentStep = stepIndex + 1
        setState(prev => ({ ...prev, currentStep }))
    }, [stepIndex])

    // Update state every second
    useEffect(() => {
        const { start, end, duration } = course?.nextClass || {}
        if (!start || !end || !duration) return

        const calculateSessionProgress = () => {
            const now = DayJS()
            const startTime = DayJS(start)
            const endTime = DayJS(end)
            const sessionDuration = DayJS.duration(...duration)
            const sessionRemainingDuration = DayJS.duration(endTime.diff(now), 'milliseconds')
            const isInRange = now.isBetween(startTime, endTime)

            const leftPercentage = ((sessionRemainingDuration.asSeconds() / sessionDuration.asSeconds()) * 100).toFixed(0)
            setState(prev => ({
                ...prev,
                isInRange,
                leftTime: sessionRemainingDuration.format('H:mm:ss'),
                sessionTime: sessionDuration.format('H:mm') + 'hs',
                leftPercentage,
            }))
        }

        const timerId = setInterval(calculateSessionProgress, 1000)
        return () => clearInterval(timerId)
    }, [course?.nextClass])

    const handleStepClick = (value: number) => {
        const newStep = session.steps[value - 1]
        navigate(`/${slugOrId}/${newStep.id}`)
    }

    if (!stepId || session.status !== 'inProgress' || !isInRange) return null

    const showCurrentStep = currentStep >= 1 && totalSteps >= 1

    return (
        <section className={styles.container} onClick={e => e.stopPropagation()}>
            <span className={styles.steps}>
                {showCurrentStep && (
                    <>
                        <b>{currentStep}</b> {t('of')} {totalSteps}
                    </>
                )}
            </span>
            <Progression className={styles.progression} percent={state.leftPercentage}>
                {session.steps.map((step, index) => (
                    <span
                        key={step.id}
                        onClick={() => handleStepClick(index + 1)}
                        data-tooltip={step.title}
                        data-tooltip-position="bottom"
                        children={index === stepIndex && <span className={styles.dot} />}
                    />
                ))}
            </Progression>
            <span
                className={styles.leftTime}
                data-tooltip={t('Session duration: {{ sessionTime}}', { sessionTime })}
                data-tooltip-position="bottom"
            >
                {state.leftTime}
            </span>
        </section>
    )
}

const Progression = styled.div<{ percent: string }>`
    background: ${({ percent }) => `linear-gradient(to left, var(--color-comp-energy-blue) ${percent ?? 0}%, #d8dadb 0)`};
`
