import { useQuery } from '@tanstack/react-query'
import { getContentStep } from '../infrastructure/api-content'
import { useToken } from './useToken'
import { GACategory, SendGAEvent } from '../helpers/GAEvents'

export const useContentStep = (stepId?: string) => {
    const { _classId } = useToken()

    const queryKey = ['Study Plan', _classId, 'Step', stepId]

    const { data: step, ...rest } = useQuery({
        queryKey,
        queryFn: async () => {
            SendGAEvent(GACategory.CONTENT, 'Step seen', stepId)
            return getContentStep(stepId!)
        },
        enabled: !!stepId,
    })

    return { step, ...rest }
}
