import { useEffect, useRef } from 'react'

const excludedTargetIds = ['sentry-feedback']

/**
 * Handle keyboard shortcuts
 * @param handles Array of shortcuts to handle
 * @param options Options for the hook
 * @example useKeyboard([{
 *      key: 'Escape',
 *      modifiers: { ctrl: true },
 *      onKeyPressed: () => console.log('Ctrl + Escape pressed'),
 *     }])
 */
export function useKeyboard(handles: UseKeyboardShortcutArgs[], options: UseKeyboardOptions = {}) {
    const { debug = false, target = typeof document !== 'undefined' ? document : undefined } = options

    useEffect(() => {
        // Ignore if target is not defined, for example in SSR
        if (!target) return

        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if the event target is one of the ignored targets
            const targetId = (event.target as HTMLElement)?.id
            if (excludedTargetIds.includes(targetId)) return

            // Debug mode
            if (debug) {
                console.log(`Key pressed: ${event.key}`, {
                    targetId: (event.target as HTMLElement)?.id,
                    ctrlKey: event.ctrlKey,
                    shiftKey: event.shiftKey,
                    altKey: event.altKey,
                    metaKey: event.metaKey,
                })
            }

            handles.forEach(({ key, modifiers, onKeyPressed, preventDefault = true }) => {
                const matchesKey = event.key === key
                const matchesModifiers =
                    !modifiers ||
                    ((!modifiers.ctrl || event.ctrlKey) &&
                        (!modifiers.shift || event.shiftKey) &&
                        (!modifiers.alt || event.altKey) &&
                        (!modifiers.meta || event.metaKey))

                if (matchesKey && matchesModifiers) {
                    if (preventDefault) event.preventDefault()
                    onKeyPressed()
                }
            })
        }

        // Listeners
        target.addEventListener('keydown', handleKeyDown as EventListener)
        return () => target.removeEventListener('keydown', handleKeyDown as EventListener)
    }, [handles, debug, target])
}

/**
 * Hook to handle simultaneous pressing of multiple keys
 * @param keys Keys to handle
 * @param onKeysPressed Callback to execute when all keys are pressed
 * @example useMultiKeyPress(['Control', 'Shift', 'P'], () => console.log('Ctrl + Shift + P pressed'))
 */
export function useMultiKeyPress(keys: KeyboardKey[], onKeysPressed: () => void) {
    const pressedKeys = useRef(new Set<string>())

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if the event target is one of the ignored targets
            const targetId = (event.target as HTMLElement)?.id
            if (excludedTargetIds.includes(targetId)) return

            pressedKeys.current.add(event.key)

            if (keys.every(key => pressedKeys.current.has(key))) {
                onKeysPressed()
            }
        }

        const handleKeyUp = (event: KeyboardEvent) => {
            pressedKeys.current.delete(event.key)
        }

        // Listeners
        document.addEventListener('keydown', handleKeyDown as EventListener)
        document.addEventListener('keyup', handleKeyUp as EventListener)
        return () => {
            document.removeEventListener('keydown', handleKeyDown as EventListener)
            document.removeEventListener('keyup', handleKeyUp as EventListener)
        }
    }, [keys, onKeysPressed])
}

// Interfaces
type SpecialKey = 'Enter' | 'Escape' | 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight' | 'Backspace' | 'Tab' | 'Space'
type KeyboardKey = string | SpecialKey

interface KeyModifiers {
    ctrl?: boolean
    shift?: boolean
    alt?: boolean
    meta?: boolean
}

interface UseKeyboardShortcutArgs {
    key: KeyboardKey
    modifiers?: KeyModifiers
    onKeyPressed: () => void
    preventDefault?: boolean
}

interface UseKeyboardOptions {
    debug?: boolean
    target?: HTMLElement | Document
}
