import { ComponentProps, Dispatch, createContext, useContext, useReducer, useState } from 'react'

import toast, { Toast, Toaster } from 'react-hot-toast'
import ReactModal from 'react-modal'
import { CertificateModal } from '../components/Modal/CertificateModal'
import { ConflictWarning } from '../components/Modal/ConflictWarning.modal'
import { LogoutModal } from '../components/Modal/Logout.modal'
import { MyProfileDataModal } from '../components/Modal/MyProfileData.modal'
import { UserBalanceGlossary } from '../components/Modal/UserBalanceGlossary.modal'
import { VideoAutoClose, type VideoProps } from '../components/Video'
import { getMessageFromError } from '../helpers/errorsHelpers'
import { modalCards } from '../styles/themes/defaultTheme'
import { CallToCoachModal } from '../components/Modal/CallToCoach.modal'
import { OpenUrl } from '../components/Modal/OpenUrl.modal'

const defaultValues: ContextType = {
    selectedModal: null,
    dispatchModal: () => {},
    discordShow: false,
    setDiscordShow: () => false,
    showBlur: false,
    setShowBlur: () => false,
    modalClose: () => undefined,
    isFullScreen: false,
    setIsFullScreen: () => undefined,
    showToast: () => '',
    showError: () => '',
}
export const UIContext = createContext<ContextType>(defaultValues)

type ContextType = {
    selectedModal: JSX.Element | null
    dispatchModal: Dispatch<Action>
    discordShow: boolean
    setDiscordShow: Dispatch<any>
    showBlur: boolean
    setShowBlur: Dispatch<any>
    modalClose: () => void
    isFullScreen: boolean
    setIsFullScreen: Dispatch<boolean>
    // eslint-disable-next-line no-unused-vars
    showToast: (message: Toast['message'], options?: Partial<Omit<Toast, 'message'>>) => string
    // eslint-disable-next-line no-unused-vars
    showError: (error: unknown, options?: Partial<Omit<Toast, 'message' | 'type'>>) => string
}
type IProps = {
    children: React.ReactNode
}

type Action =
    | { type: ModalType.WARNING_PWDC_CONFLICT; payload: () => void }
    | { type: ModalType.TYC; payload: 'update' | 'first-time' }
    | { type: ModalType.CERTIFICATE; payload: any }
    | { type: ModalType.MY_PROFILE_DATA }
    | { type: ModalType.CLOSE_SESSION }
    | { type: ModalType.VIDEO; payload: VideoProps }
    | { type: ModalType.USER_BALANCE_GLOSSARY }
    | { type: ModalType.CALL_TO_COACH; payload: { tableNumber: number } }
    | { type: ModalType.OPEN_URL; payload: ComponentProps<typeof OpenUrl> }
    | { type: null }

export enum ModalType {
    TYC = 'tyc',
    CERTIFICATE = 'certificate',
    MY_PROFILE_DATA = 'my-profile-data',
    WARNING_PWDC_CONFLICT = 'warning-PWDC-conflict',
    CLOSE_SESSION = 'close-session',
    VIDEO = 'video',
    USER_BALANCE_GLOSSARY = 'user-balance-glossary',
    CALL_TO_COACH = 'call-to-coach',
    OPEN_URL = 'open-url',
}

const reducer = (_state: any, action: Action) => {
    switch (action.type) {
        case ModalType.CERTIFICATE:
            return <CertificateModal payload={action.payload} />
        case ModalType.MY_PROFILE_DATA:
            return <MyProfileDataModal />
        case ModalType.WARNING_PWDC_CONFLICT:
            return <ConflictWarning onRegister={action.payload} />
        case ModalType.CLOSE_SESSION:
            return <LogoutModal />
        case ModalType.VIDEO:
            return (
                <VideoAutoClose
                    className="course-info-video"
                    playing={true}
                    stopOnUnmount={true}
                    pip={true}
                    controls={true}
                    {...action.payload}
                />
            )
        case ModalType.USER_BALANCE_GLOSSARY:
            return <UserBalanceGlossary />

        case ModalType.CALL_TO_COACH:
            return <CallToCoachModal {...action.payload} />

        case ModalType.OPEN_URL:
            return <OpenUrl {...action.payload} />
        default:
            return null
    }
}

const initialState = null

const UIContextProvider = ({ children }: IProps) => {
    const [selectedModal, dispatchModal] = useReducer(reducer, initialState)
    const [discordShow, setDiscordShow] = useState<false>(false)
    const [showBlur, setShowBlur] = useState<boolean>(false)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const modalClose = () => {
        dispatchModal({ type: null })
        setIsFullScreen(false)
    }

    const showToast = (message: Toast['message'], options?: Partial<Omit<Toast, 'message'>>) => toast(message, options)

    const showError = (error: unknown, options?: Partial<Omit<Toast, 'message' | 'type'>>) =>
        showToast(getMessageFromError(error), { type: 'error', ...options })

    const values = {
        selectedModal,
        dispatchModal,
        discordShow,
        setDiscordShow,
        setShowBlur,
        showBlur,
        modalClose,
        isFullScreen,
        setIsFullScreen,
        showToast,
        showError,
    }

    return (
        <UIContext.Provider value={values}>
            <Toaster
                containerStyle={{
                    top: '2.12rem',
                }}
                toastOptions={{
                    style: {
                        color: '#FFF',
                        fontSize: '15px',
                        fontWeight: 600,
                        borderRadius: '0.625rem',
                        border: '1px solid #414141',
                        background: 'rgba(47, 48, 50, 0.7)',
                        backdropFilter: 'blur(4px)',
                    },
                    custom: {
                        style: {
                            backgroundColor: 'tomato',
                        },
                    },
                }}
            />
            {children}
            {selectedModal !== null && (
                <ReactModal
                    style={modalCards}
                    isOpen={true}
                    onRequestClose={modalClose}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    className={`${isFullScreen ? 'modal-full-screen-mobile' : ''}`}
                >
                    {selectedModal}
                </ReactModal>
            )}
        </UIContext.Provider>
    )
}

export const useUIContext = () => useContext(UIContext)

export default UIContextProvider

ReactModal.setAppElement('#root')
