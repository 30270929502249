import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { type PlanStepDTO } from '../../infrastructure/api-content'
import { defaultTheme } from '../../styles/themes/defaultTheme'
import { Icon } from '../Icon'

export const StpListItem = ({ step: { id, title, status }, active }: { step: PlanStepDTO; active?: boolean }) => {
    const { slugOrId } = useParams()
    const navigate = useNavigate()

    const iconStyle = {
        color:
            status === 'inProgress'
                ? defaultTheme.color.primary
                : status === 'done'
                ? defaultTheme.color.greenHarmony
                : defaultTheme.color.gray,
    }

    return (
        <StyleWrapper
            data-stepid={id}
            onClick={() => navigate(`/${slugOrId}/${id}`)}
            className={active ? 'active' : 'un-active'}
            disabled={status === 'blocked'}
        >
            <Icon icon={['fas', 'circle']} size="sm" style={{ position: 'relative', ...iconStyle }} className="steps-padding step-icon" />
            <div>{title}</div>
        </StyleWrapper>
    )
}

const StyleWrapper = styled.button`
    all: unset;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${defaultTheme.color.offWhite};
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        & > div:hover {
            background-color: unset;
        }
    }

    & > div {
        flex-grow: 2;
        padding: 0.5em;
        margin: 1em;
        border-radius: 6px;

        &:hover {
            background-color: ${defaultTheme.color.lightGray};
        }
    }

    &.active div {
        background-color: ${defaultTheme.color.electricBlue};
        color: white;
    }

    &:last-child {
        border-bottom: none;
    }
`
