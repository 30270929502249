import { useQuery } from '@tanstack/react-query'
import { getUserStudyPlan as queryFn } from '../infrastructure/api-content'

export const useCourseStudyPlan = (courseId?: string) => {
    const queryKey = ['Study Plan', courseId]

    const { data: plan, ...rest } = useQuery({
        queryKey,
        queryFn,
        enabled: !!courseId,
    })

    const currentSessionId = plan?.currentSessionId
    const currentSession = plan?.sessions.find(({ id }) => currentSessionId === id)
    const firstSession = plan?.sessions.at(0)
    const firstStepId = firstSession?.steps.at(0)?.id

    const findSessionById = (sessionId?: string) => plan?.sessions.find(({ id }) => id === sessionId)
    const findSessionByStepId = (stepId?: string) => plan?.sessions.find(session => session.steps.some(({ id }) => id === stepId))
    const findStepById = (stepId?: string) =>
        plan?.sessions.map(session => session.steps.find(({ id }) => id === stepId)).find(step => step)

    return {
        plan,
        firstSession,
        firstStepId,
        currentSessionId,
        currentSession,
        findSessionByStepId,
        findSessionById,
        findStepById,
        queryKey,
        ...rest,
    }
}
