import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useUserCourse } from '../../hooks/queries/useUserCourses'
import { useCourseStudyPlan } from '../../hooks/useCourseStudyPlan'
import { useKeyboard } from '../../hooks/useKeyboard'
import { Icon } from '../Icon'
import { StickyOnTop } from '../StickyOnTop'
import { TranslationWidget } from '../TranslationWidget'
import styles from './ContentNavigation.module.css'
import { SessionStepList } from './SessionStepList'

export const ContentNavigation = () => {
    const navigate = useNavigate()
    const { t } = useTranslation('course')
    const { slugOrId, stepId } = useParams()
    const { courseId } = useUserCourse(slugOrId)
    const { currentSession, findStepById, findSessionByStepId, status: planStatus } = useCourseStudyPlan(courseId)

    const step = findStepById(stepId)
    const firstStepId = currentSession?.steps.at(0)?.id
    const isCurrentSession = !!currentSession?.steps.find(({ id }) => id === stepId)
    const stepSession = findSessionByStepId(stepId)
    const session = !!stepId && stepSession

    // Keyboard navigation
    useKeyboard([
        {
            key: 'ArrowRight',
            onKeyPressed: () => step?.nextStep && navigate(`/${slugOrId}/${step.nextStep}`),
        },
        {
            key: 'ArrowLeft',
            onKeyPressed: () => step?.prevStep && navigate(`/${slugOrId}/${step.prevStep}`),
        },
        {
            key: 'i',
            onKeyPressed: () => navigate(`/${slugOrId}`),
        },
        {
            key: 't',
            onKeyPressed: () => firstStepId && navigate(`/${slugOrId}/${firstStepId}`),
        },
    ])

    if (planStatus === 'pending') return <ContentNavigationSkeleton />

    return (
        <StickyOnTop
            style={{
                padding: '8px 0',
                backgroundColor: '#f8f8f8',
                top: -2,
            }}
        >
            <nav className={`${styles.nav} ${planStatus}`}>
                <div>
                    <NavLink
                        to={`/${slugOrId}`}
                        className={({ isActive }) => (isActive ? `${styles.linkIndex} ${styles.active} tooltip` : styles.linkIndex)}
                        end
                        data-tooltip={t('Go to index') + ' (i)'}
                        data-tooltip-position="bottom"
                    >
                        <>
                            <Icon icon={['far', 'bars']} /> {t('Index')}
                        </>
                    </NavLink>

                    <NavLink
                        to={`/${slugOrId}/${firstStepId}`}
                        aria-disabled={!firstStepId}
                        className={isCurrentSession ? `${styles.linkToday} ${styles.active}` : styles.linkToday}
                        data-tooltip={t(`Today's content`) + ' (t)'}
                        data-tooltip-position="right"
                    >
                        <>
                            <Icon icon={['far', 'map-pin']} /> {t('Today')}
                        </>
                    </NavLink>
                </div>

                {step && (
                    <div>
                        <TranslationWidget />

                        <NavLink
                            to={`/${slugOrId}/${step?.prevStep}`}
                            aria-disabled={!step.prevStep}
                            className={styles.arrows}
                            data-tooltip={t('Previous step') + ' (⬅)'}
                            data-tooltip-position="bottom"
                        >
                            <Icon icon={['fas', 'arrow-left']} size="xl" />
                        </NavLink>
                        <NavLink
                            to={`/${slugOrId}/${step?.nextStep}`}
                            aria-disabled={!step.nextStep}
                            className={styles.arrows}
                            data-tooltip={t('Next step') + ' (➡)'}
                            data-tooltip-position="bottom"
                        >
                            <Icon icon={['fas', 'arrow-right']} size="xl" />
                        </NavLink>
                    </div>
                )}
            </nav>

            {session && <SessionStepList session={session} />}
        </StickyOnTop>
    )
}

const ContentNavigationSkeleton = () => (
    <div style={{ padding: '8px 0' }}>
        <div className="skeleton" style={{ height: 44.78, marginBottom: '1em' }} />
        <div className="skeleton" style={{ height: 70, marginBottom: '1em' }} />
    </div>
)
