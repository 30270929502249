import Axios from 'axios'
import { Document as RichTextDocument } from '@contentful/rich-text-types'

import { refreshInterceptor } from './apis'

if (!process.env.REACT_APP_API_CONTENT) throw new Error('REACT_APP_API_CONTENT to be declared on your system environments variables')

export const ApiContent = Axios.create({
    baseURL: process.env.REACT_APP_API_CONTENT,
})

ApiContent.interceptors.response.use(
    ok => ok,
    error => refreshInterceptor(error, ApiContent)
)

export const getUserStudyPlan = () => ApiContent.get<API<UserStudyPlanDTO>>(`v1/profiles/study-plans`).then(({ data }) => data.data)

export const getContentStep = (stepId: string) =>
    ApiContent.get<API<ContentStepDTO>>(`v1/profiles/study-plans/step/${stepId}`).then(({ data }) => data.data)

// Interfaces
type API<TData> = {
    code: 200
    message: string
    data: TData
}

export interface UserStudyPlanDTO {
    id: string
    courseId: string
    language: string
    currentSessionId?: string
    sessions: {
        id: string
        title: string
        status: StudyPlanStatus
        number: number
        steps: {
            id: string
            title: string
            status: StudyPlanStatus
            nextStep: string
            prevStep: string
        }[]
    }[]
}

export type PlanSessionDTO = UserStudyPlanDTO['sessions'][0]
export type PlanStepDTO = PlanSessionDTO['steps'][0]

export type StudyPlanStatus = 'done' | 'inProgress' | 'blocked'

export interface ContentStepDTO {
    _id: string
    title: string
    principalMedia?: any
    slug?: string
    description: RichTextDocument
    suggestedExercise?: RichTextDocument
}
