import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useUserCourse } from '../../hooks/queries/useUserCourses'
import { useEffect } from 'react'
import { setPresentStatus } from '../../helpers/userAttendance'
import { setNewAccessToken } from '../../infrastructure/apis'
import { useOpenMeet } from '../../hooks/useOpenMeet'
import { useCourseStudyPlan } from '../../hooks/useCourseStudyPlan'
import { useTranslation } from 'react-i18next'
import { CourseContentList } from './CourseContentList'

export const CourseManager = () => {
    const { slugOrId } = useParams()
    const { courseId, course, status } = useUserCourse(slugOrId)
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const hasOpenMeet = params.get('meet')
    const hasStep = params.get('step')

    const { openMeet } = useOpenMeet(courseId)
    const { firstStepId } = useCourseStudyPlan(courseId)

    // Set course access token and present when course is loaded
    useEffect(() => {
        if (slugOrId === 'undefined') navigate('/home')
        if (!course) return
        setNewAccessToken(course['access-token'])
        window.history.replaceState({}, '', window.location.pathname)
        setPresentStatus(course)
        if (hasOpenMeet) {
            openMeet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Open first step if hasStep is true
    useEffect(() => {
        if (!course) return
        if (!hasStep || !firstStepId || !course) return
        if (hasStep && firstStepId) {
            navigate(`/content/step/${firstStepId}`)
        }
    }, [course, hasStep, firstStepId, navigate])

    if (status === 'success' && !course) return <CourseNotFound />

    return <CourseContentList slugOrId={slugOrId} />
}

const CourseNotFound = () => {
    const { t } = useTranslation('Courses')

    return <div>{t('This course was not available')}</div>
}
